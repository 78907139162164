import React, { useEffect, useState, useRef } from 'react';
import { compose } from 'redux';
import { ReactForms } from '@rexlabs/form';
import { withQuery, query } from '@rexlabs/model-generator';
import settings from 'data/models/entities/settings';
import { withToast } from 'view/components/toast';
import PreviewLayout from '../../layouts/preview';
import { menusQuery } from 'data/queries/menus';
import { RenderLoading } from 'view/components/render-loading';
import EditHeaderSettings, {
  mapPropsToValues
} from 'view/forms/edit-header-settings';
import { websiteQuery } from 'data/queries/websites';

function getWebsiteId({ match }) {
  return match?.params?.websiteId;
}

function getSettingsKeys() {
  const siteSettingsKeys = [
    'header_menu',
    'menu_background_color',
    'header_accent_color',
    'menu_font_color',
    'menu_font_hover_color',
    'menu_dropdown_background_color',
    'menu_dropdown_font_color',
    'menu_dropdown_font_hover_color',
    'show_contact_details',
    'contact_details_font_color',
    'header_behaviour',
    'blog_feature_size',
    'transparent_header',
    'disable_transparent_header',
    'boxed_logo',
    'transparent_header_hide_logo',
    'transparent_header_font_color',
    'menu_item_hover_color',
    'versatile_header_contact_link',
    'header_background_color',
    'header_font_color',
    'mobile_menu_background_color',
    'mobile_menu_font_color',
    'default_header_logo',
    'transparent_header_logo',
    'header_phone',
    'header_email',
    'header_address'
  ];

  return `key.in(["${siteSettingsKeys.join('", "')}"])`;
}

const settingsQuery = query`{
  ${settings} (websiteId: ${getWebsiteId}, q: ${getSettingsKeys})  {
    key
    value
    label
  }
 }
`;

function Header(props) {
  const { menus, settings, addToast } = props;
  const { theme_id: themeId } = props.websites.item.data;

  const defaultPreviewProps = {
    websiteId: getWebsiteId(props),
    values: {
      settings: {},
      component: 'index.twig'
    }
  };
  const [previewProps, setPreviewProps] = useState(defaultPreviewProps);
  const initialPreviewPropsSet = useRef(false);
  const isLoading = !(
    props.settings.list.status === 'loaded' &&
    props.menus.list.status === 'loaded'
  );

  const handleSubmit = async (values) => {
    try {
      const data = Object.keys(values).reduce((acc, key) => {
        const value = values[key];
        acc.push({
          key: key,
          value: [value?.value ?? value],
          category: settings.list?.args?.category || 'style',
          type: key === 'header_menu' ? 'menu' : null
        });

        return acc;
      }, []);

      await settings.updateSettings({
        websiteId: props.match.params?.websiteId,
        data: {
          settings: data
        }
      });

      setPreviewProps({
        ...defaultPreviewProps,
        values: { ...defaultPreviewProps, settings: values }
      });
    } catch (e) {
      addToast({
        title: 'Error',
        content: e?.message ?? 'An unknown error has occurred',
        type: 'error'
      });
    }
  };

  useEffect(() => {
    window.analytics?.page?.('Header behaviour');
  }, []);

  useEffect(() => {
    if (initialPreviewPropsSet.current || isLoading) return;
    initialPreviewPropsSet.current = true;

    setPreviewProps({
      ...defaultPreviewProps,
      values: { ...defaultPreviewProps, settings: mapPropsToValues(props) }
    });
  }, [isLoading, props, defaultPreviewProps]);

  return (
    <RenderLoading isLoading={isLoading}>
      <ReactForms
        initialValues={mapPropsToValues(props)}
        handleSubmit={handleSubmit}
      >
        {({ isSubmitting, submitForm }) => {
          return (
            <PreviewLayout
              Settings={
                <EditHeaderSettings
                  isSubmitting={isSubmitting}
                  submitForm={submitForm}
                  menus={menus}
                  themeId={themeId}
                />
              }
              previewProps={previewProps}
            />
          );
        }}
      </ReactForms>
    </RenderLoading>
  );
}

export default compose(
  withToast,
  withQuery(settingsQuery),
  withQuery(menusQuery),
  withQuery(websiteQuery)
)(Header);
