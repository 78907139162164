/* eslint-disable */

import React, { PureComponent } from 'react';
import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import { ReactForms, Form, Field } from '@rexlabs/form';
import { TextInput, TextArea } from '@rexlabs/text-input';
import actions from 'data/models/entities/actions';
import { get } from 'lodash';
import { withQuery, query } from '@rexlabs/model-generator';
import { Dropdown } from '@rexlabs/select';
import { Dialog } from '@rexlabs/dialog';
import { autobind } from 'core-decorators';
import { PrimaryButton } from '@rexlabs/button';
import { actionQuery } from 'src/data/queries/actions';
import { useToast } from 'view/components/toast';
import { CodeInput } from '@rexlabs/code-input';

const defaultStyles = StyleSheet({
  container: {
    position: 'relative',
    padding: '2.4rem'
  }
});

function getWebsiteId(props) {
  return get(props, 'match.params.websiteId');
}

function getFormId(props) {
  return get(props, 'match.params.formId');
}

const RedirectActionForm = ({ data }) => {
  return (
    <Box>
      <Box padding='10px' flex={1}>
        <Field label='Redirect Url' name='redirect_url' Input={TextInput} />
      </Box>
    </Box>
  );
};

const SuccessActionForm = ({ data }) => {
  return (
    <Box>
      <Box padding='10px' flex={1}>
        <Field
          label='Success Message'
          name='success_message'
          Input={CodeInput}
          inputProps={{
            mode: 'text',
            theme: 'github',
            charLimit: 9000
          }}
        />
      </Box>
    </Box>
  );
};

const EmailActionForm = ({ data }) => {
  return (
    <Box>
      <Box flexDirection='row' flex={1}>
        <Box padding='10px' flex={1}>
          <Field
            label='To email'
            name='email_to'
            Input={TextInput}
            optional={false}
          />
        </Box>

        <Box padding='10px' flex={1}>
          <Field
            label='Reply To Email'
            name='email_reply_to'
            Input={TextInput}
          />
        </Box>
      </Box>

      <Box padding='10px' flex={1}>
        <Field
          label='Subject'
          name='email_subject'
          Input={TextInput}
          optional={false}
        />
      </Box>

      <Box flex={1} padding='10px'>
        <Field label='Email message' name='email_message' Input={TextArea} />
      </Box>
    </Box>
  );
};

const actionsQuery = query`{
  ${actions} (websiteId: ${getWebsiteId}, formId: ${getFormId}) {
    id,
    name,
    form_id,
    type,
    email_to,
    email_reply_to,
    email_subject,
    email_message,
    redirect_url
  }
}`;
@withQuery(actionsQuery)
@withQuery(actionQuery)
@styled(defaultStyles)
@autobind
class FormActionScreen extends PureComponent {
  async handleSubmit(values) {
    // const { addToast } = useToast();
    const { actions, match } = this.props;
    const { type, ...otherValues } = values;

    try {
      await actions.updateItem({
        id: actions?.item?.data?.id,
        data: {
          ...otherValues,
          type: type.value
        },
        args: {
          websiteId: match?.params?.websiteId,
          formId: match?.params?.formId
        }
      });
      actions.refreshList({ id: actionsQuery.args.formId });
      // addToast?.({
      //   type: 'success',
      //   title: 'Saved',
      //   content: 'Action successfully saved.'
      // });
      this.props.onClose();
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    const { styles: s, actions, actionForm } = this.props;
    const action = actions?.item?.data;

    return (
      <Dialog
        title='Edit Action'
        onClose={this.props.onClose}
        width={'80%'}
        {...this.props}
      >
        <Box flex={1}>
          <Box flexDirection='column' flex={1} {...s('container')}>
            <Box flexDirection='column' flex={1}>
              <Box flexDirection='row' flex={1}>
                <ReactForms
                  style={{ width: '100%' }}
                  handleSubmit={this.handleSubmit}
                  initialValues={{
                    name: action?.name,
                    type: {
                      value: action?.type?.id,
                      label: action?.type?.label
                    },
                    email_to: action?.email_to,
                    email_reply_to: action?.email_reply_to,
                    email_subject: action?.email_subject,
                    email_message: action?.email_message,
                    redirect_url: action?.redirect_url,
                    success_message: action?.success_message
                  }}
                  name='actionForm'
                >
                  {({ values, submitForm, isSubmitting }) => (
                    <Form>
                      <Box flexDirection='row' flex={1}>
                        <Box padding='10px' flex={1}>
                          <Field
                            label='Action name'
                            name='name'
                            Input={TextInput}
                            required
                          />
                        </Box>

                        <Box padding='10px' flex={1} flexDirection='column'>
                          <Field
                            label='Type'
                            name='type'
                            Input={Dropdown}
                            inputProps={{
                              items: [
                                { value: 'email', label: 'Email' },
                                { value: 'redirect', label: 'Redirect' },
                                { value: 'success', label: 'Success Message' }
                              ]
                            }}
                          />
                        </Box>
                      </Box>

                      {(values?.type?.value === 'email' && (
                        <EmailActionForm data={values} />
                      )) ||
                        (values?.type?.value === 'redirect' && (
                          <RedirectActionForm data={values} />
                        )) ||
                        (values?.type?.value === 'success' && (
                          <SuccessActionForm data={values} />
                        ))}

                      <Box alignItems='flex-start' mt='2.4rem' padding='10px'>
                        <PrimaryButton
                          onClick={submitForm}
                          disabled={isSubmitting}
                        >
                          Save Action
                        </PrimaryButton>
                      </Box>
                    </Form>
                  )}
                </ReactForms>
              </Box>
            </Box>
          </Box>
        </Box>
      </Dialog>
    );
  }
}

export default FormActionScreen;
