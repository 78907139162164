import React, { PureComponent } from 'react';
import Box from '@rexlabs/box';
import { ReactForms, Form, Field } from '@rexlabs/form';
import { RenderLoading } from 'view/components/render-loading';
import { withQuery, query } from '@rexlabs/model-generator';
import { autobind } from 'core-decorators';
import settings from 'data/models/entities/settings';
import { handleSettingsSubmit } from 'utils/handle-settings-submit';
import { PrimaryButton } from '@rexlabs/button';
import { withToast } from 'view/components/toast';
import { CodeInput } from '@rexlabs/code-input';
import { Dropdown } from '@rexlabs/select';
import { Checkbox } from '@rexlabs/checkbox';
import { Heading } from '@rexlabs/text';
import { TextInput } from '@rexlabs/text-input';

function getWebsiteId(props) {
  return props?.match?.params?.websiteId;
}

const settingQuery = query`{
  ${settings} (websiteId: ${getWebsiteId}, category: listings) {
    key
    value
    label
    category
  }
}`;

const mapProps = (props) => {
  const { settings } = props;
  const settingsData = settings?.list?.items;

  const data = Object.assign(
    {},
    ...settingsData.map((object) => {
      return { [object.key]: object?.value ? object?.value[0] : null };
    })
  );

  return data;
};

const listingSearch = {
  name: 'listingSearch',
  mapPropsToValues: mapProps,
  handleSubmit: handleSettingsSubmit,
  asyncValuesReady: (props) => {
    return props.settings.list.status === 'loaded';
  }
};

const SEARCH_VIEW_OPTIONS = [
  { label: 'Gallery', value: 'gallery' },
  { label: 'List', value: 'list' },
  { label: 'Map', value: 'map' }
];

export const SHOW_HIDE_OPTIONS = [
  { label: 'Show', value: true },
  { label: 'Hide', value: false }
];

@withToast
@withQuery(settingQuery)
@autobind
class ListingSearch extends PureComponent {
  render() {
    return (
      <Box
        flex={1}
        style={{ padding: '20px 20px 100px 20px', overflow: 'auto' }}
      >
        <RenderLoading isLoading={this.props.settings.list.status !== 'loaded'}>
          <ReactForms
            initialValues={listingSearch.mapPropsToValues(this.props)}
            handleSubmit={(values) =>
              listingSearch.handleSubmit(values, { props: this.props })
            }
          >
            {({ submitForm, isSubmitting }) => (
              <Form name='listingSearch' style={{ width: '100%' }}>
                <Box
                  flex={1}
                  flexDirection={'row'}
                  padding='10px'
                  alignItems={'center'}
                >
                  <h1
                    style={{
                      fontSize: '28px',
                      fontWeight: '700',
                      marginRight: '20px'
                    }}
                  >
                    {' '}
                    Listings Search Settings{' '}
                  </h1>

                  <PrimaryButton onClick={submitForm} isLoading={isSubmitting}>
                    Save
                  </PrimaryButton>
                </Box>

                <Heading
                  style={{
                    marginTop: '20px',
                    marginBottom: '20px',
                    marginLeft: '10px'
                  }}
                >
                  Available Views
                </Heading>

                <Box padding='10px' flex={1}>
                  <Field
                    name='listing_search_show_gallery'
                    label='Show Gallery View'
                    optional={false}
                    Input={Checkbox}
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  <Field
                    name='listing_search_show_list'
                    label='Show List View'
                    optional={false}
                    Input={Checkbox}
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  <Field
                    name='listing_search_show_map'
                    label='Show Map View'
                    optional={false}
                    Input={Checkbox}
                  />
                </Box>

                <Box padding={10} flex={1}>
                  <Field
                    label='Default View'
                    name='listing_search_default_view'
                    optional={false}
                    Input={Dropdown}
                    inputProps={{
                      items: SEARCH_VIEW_OPTIONS
                    }}
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  <Field
                    name='listing_search_preserve_image_aspect'
                    label='Preserve aspect ratio of listing images'
                    optional={false}
                    Input={Checkbox}
                    HelpTooltipContent={() =>
                      'This will not crop the primary listing image on search pages, but may result in inconsistent layouts if inconsistent image sizes are used'
                    }
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  <Field
                    name='listing_new_for_x_days'
                    label='Mark listing as "New" for the first X days on website'
                    Input={TextInput}
                    optional={false}
                    HelpTooltipContent={() =>
                      'Leave blank or set to zero to remove the "New" label altogether'
                    }
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  <PrimaryButton onClick={submitForm} isLoading={isSubmitting}>
                    Save
                  </PrimaryButton>
                </Box>

                <Heading
                  style={{
                    marginTop: '40px',
                    marginBottom: '20px',
                    marginLeft: '10px'
                  }}
                >
                  Basic Filters
                </Heading>

                <Box padding='10px' flex={1}>
                  <Field
                    label='Availability default value'
                    name='listing_search_filters_status_default'
                    optional={false}
                    Input={Dropdown}
                    inputProps={{
                      items: [
                        { label: 'All', value: 'all' },
                        { label: 'Available', value: 'available' },
                        {
                          label: 'Available (including Under Offer)',
                          value: 'available_under_contract'
                        },
                        { label: 'Sold / Leased', value: 'sold' }
                      ]
                    }}
                    HelpTooltipContent={() =>
                      'When no value for Availability is selected on a search this will be applied to help filter results'
                    }
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  <Field
                    label='Buy / Lease toggle'
                    name='listing_search_filters_buy_lease'
                    optional={false}
                    Input={Dropdown}
                    inputProps={{
                      items: SHOW_HIDE_OPTIONS
                    }}
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  <Field
                    label='Buy / Lease default value'
                    name='listing_search_filters_buy_lease_default'
                    optional={false}
                    Input={Dropdown}
                    inputProps={{
                      items: [
                        { label: 'Buy', value: 'Sale' },
                        { label: 'Lease', value: 'Rental' }
                      ]
                    }}
                    HelpTooltipContent={() =>
                      'When no value for Buy / Lease is selected on a search this will be applied to help filter results'
                    }
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  {/* <Field
                    name='listing_search_filters_free'
                    label='Suburb search'
                    optional={false}
                    Input={Checkbox}
                  /> */}
                  <Field
                    label='Free-text search'
                    name='listing_search_filters_free'
                    optional={false}
                    Input={Dropdown}
                    inputProps={{
                      items: SHOW_HIDE_OPTIONS
                    }}
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  {/* <Field
                    name='listing_search_filters_suburbs'
                    label='Suburb search'
                    optional={false}
                    Input={Checkbox}
                  /> */}
                  <Field
                    label='Suburb search'
                    name='listing_search_filters_suburbs'
                    optional={false}
                    Input={Dropdown}
                    inputProps={{
                      items: SHOW_HIDE_OPTIONS
                    }}
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  {/* <Field
                    name='listing_search_filters_price_range'
                    label='Price range'
                    optional={false}
                    Input={Checkbox}
                  /> */}
                  <Field
                    label='Price range'
                    name='listing_search_filters_price_range'
                    optional={false}
                    Input={Dropdown}
                    inputProps={{
                      items: SHOW_HIDE_OPTIONS
                    }}
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  {/* <Field
                    name='listing_search_filters_beds'
                    label='Bedrooms'
                    optional={false}
                    Input={Checkbox}
                  /> */}
                  <Field
                    label='Bedrooms'
                    name='listing_search_filters_beds'
                    optional={false}
                    Input={Dropdown}
                    inputProps={{
                      items: SHOW_HIDE_OPTIONS
                    }}
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  {/* <Field
                    name='listing_search_filters_baths'
                    label='Bathrooms'
                    optional={false}
                    Input={Checkbox}
                  /> */}
                  <Field
                    label='Bathrooms'
                    name='listing_search_filters_baths'
                    optional={false}
                    Input={Dropdown}
                    inputProps={{
                      items: SHOW_HIDE_OPTIONS
                    }}
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  {/* <Field
                    name='listing_search_filters_cars'
                    label='Car Spaces'
                    optional={false}
                    Input={Checkbox}
                  /> */}
                  <Field
                    label='Car Spaces'
                    name='listing_search_filters_cars'
                    optional={false}
                    Input={Dropdown}
                    inputProps={{
                      items: SHOW_HIDE_OPTIONS
                    }}
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  {/* <Field
                    name='listing_search_filters_type'
                    label='Listing Type'
                    optional={false}
                    Input={Checkbox}
                  /> */}
                  <Field
                    label='Listing Type'
                    name='listing_search_filters_type'
                    optional={false}
                    Input={Dropdown}
                    inputProps={{
                      items: SHOW_HIDE_OPTIONS
                    }}
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  {/* <Field
                    name='listing_search_filters_category'
                    label='Category'
                    optional={false}
                    Input={Checkbox}
                  /> */}
                  <Field
                    label='Category'
                    name='listing_search_filters_category'
                    optional={false}
                    Input={Dropdown}
                    inputProps={{
                      items: SHOW_HIDE_OPTIONS
                    }}
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  {/* <Field
                    name='listing_search_filters_property_id'
                    label='Property ID'
                    optional={false}
                    Input={Checkbox}
                  /> */}
                  <Field
                    label='Property ID'
                    name='listing_search_filters_property_id'
                    optional={false}
                    Input={Dropdown}
                    inputProps={{
                      items: SHOW_HIDE_OPTIONS
                    }}
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  {/* <Field
                    name='listing_search_filters_build_area'
                    label='Build Area'
                    optional={false}
                    Input={Checkbox}
                  /> */}
                  <Field
                    label='Build Area'
                    name='listing_search_filters_build_area'
                    optional={false}
                    Input={Dropdown}
                    inputProps={{
                      items: SHOW_HIDE_OPTIONS
                    }}
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  {/* <Field
                    name='listing_search_filters_land_area'
                    label='Land Area'
                    optional={false}
                    Input={Checkbox}
                  /> */}
                  <Field
                    label='Land Area'
                    name='listing_search_filters_land_area'
                    optional={false}
                    Input={Dropdown}
                    inputProps={{
                      items: SHOW_HIDE_OPTIONS
                    }}
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  {/* <Field
                    name='listing_search_filters_new_homes'
                    label='Only Show New Homes'
                    optional={false}
                    Input={Checkbox}
                  /> */}
                  <Field
                    label='Only Show New Homes'
                    name='listing_search_filters_new_homes'
                    optional={false}
                    Input={Dropdown}
                    inputProps={{
                      items: SHOW_HIDE_OPTIONS
                    }}
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  {/* <Field
                    name='listing_search_filters_auctions'
                    label='Only Show Auctions'
                    optional={false}
                    Input={Checkbox}
                  /> */}
                  <Field
                    label='Only Show Listings with Auctions'
                    name='listing_search_filters_auctions'
                    optional={false}
                    Input={Dropdown}
                    inputProps={{
                      items: SHOW_HIDE_OPTIONS
                    }}
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  {/* <Field
                    name='listing_search_filters_availability'
                    label='Availability'
                    optional={false}
                    Input={Checkbox}
                  /> */}
                  <Field
                    label='Availability'
                    name='listing_search_filters_availability'
                    optional={false}
                    Input={Dropdown}
                    inputProps={{
                      items: SHOW_HIDE_OPTIONS
                    }}
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  {/* <Field
                    name='listing_search_filters_state'
                    label='State (AU) or Region (NZ)'
                    optional={false}
                    Input={Checkbox}
                  /> */}
                  <Field
                    label='State (AU) or Region (NZ)'
                    name='listing_search_filters_state'
                    optional={false}
                    Input={Dropdown}
                    inputProps={{
                      items: SHOW_HIDE_OPTIONS
                    }}
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  {/* <Field
                    name='listing_search_filters_features'
                    label='Features'
                    optional={false}
                    Input={Checkbox}
                  /> */}
                  <Field
                    label='Features'
                    name='listing_search_filters_features'
                    optional={false}
                    Input={Dropdown}
                    inputProps={{
                      items: SHOW_HIDE_OPTIONS
                    }}
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  {/* <Field
                    name='listing_search_filters_tags'
                    label='Tags'
                    optional={false}
                    Input={Checkbox}
                  /> */}
                  <Field
                    label='Tags'
                    name='listing_search_filters_tags'
                    optional={false}
                    Input={Dropdown}
                    inputProps={{
                      items: SHOW_HIDE_OPTIONS
                    }}
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  <PrimaryButton onClick={submitForm} isLoading={isSubmitting}>
                    Save
                  </PrimaryButton>
                </Box>

                <Heading
                  style={{
                    marginTop: '40px',
                    marginBottom: '20px',
                    marginLeft: '10px'
                  }}
                >
                  Advanced Filters
                </Heading>

                <Box padding='10px' flex={1}>
                  {/* <Field
                    name='listing_search_advanced_filters_buy_lease'
                    label='Buy / Lease toggle'
                    optional={false}
                    Input={Checkbox}
                  /> */}
                  <Field
                    label='Buy / Lease toggle'
                    name='listing_search_advanced_filters_buy_lease'
                    optional={false}
                    Input={Dropdown}
                    inputProps={{
                      items: SHOW_HIDE_OPTIONS
                    }}
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  {/* <Field
                    name='listing_search_advanced_filters_free'
                    label='Suburb search'
                    optional={false}
                    Input={Checkbox}
                  /> */}
                  <Field
                    label='Free-text search'
                    name='listing_search_advanced_filters_free'
                    optional={false}
                    Input={Dropdown}
                    inputProps={{
                      items: SHOW_HIDE_OPTIONS
                    }}
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  {/* <Field
                    name='listing_search_advanced_filters_suburbs'
                    label='Suburb search'
                    optional={false}
                    Input={Checkbox}
                  /> */}
                  <Field
                    label='Suburb search'
                    name='listing_search_advanced_filters_suburbs'
                    optional={false}
                    Input={Dropdown}
                    inputProps={{
                      items: SHOW_HIDE_OPTIONS
                    }}
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  {/* <Field
                    name='listing_search_advanced_filters_price_range'
                    label='Price range'
                    optional={false}
                    Input={Checkbox}
                  /> */}
                  <Field
                    label='Price range'
                    name='listing_search_advanced_filters_price_range'
                    optional={false}
                    Input={Dropdown}
                    inputProps={{
                      items: SHOW_HIDE_OPTIONS
                    }}
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  {/* <Field
                    name='listing_search_advanced_filters_beds'
                    label='Bedrooms'
                    optional={false}
                    Input={Checkbox}
                  /> */}
                  <Field
                    label='Bedrooms'
                    name='listing_search_advanced_filters_beds'
                    optional={false}
                    Input={Dropdown}
                    inputProps={{
                      items: SHOW_HIDE_OPTIONS
                    }}
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  {/* <Field
                    name='listing_search_advanced_filters_baths'
                    label='Bathrooms'
                    optional={false}
                    Input={Checkbox}
                  /> */}
                  <Field
                    label='Bathrooms'
                    name='listing_search_advanced_filters_baths'
                    optional={false}
                    Input={Dropdown}
                    inputProps={{
                      items: SHOW_HIDE_OPTIONS
                    }}
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  {/* <Field
                    name='listing_search_advanced_filters_cars'
                    label='Car Spaces'
                    optional={false}
                    Input={Checkbox}
                  /> */}
                  <Field
                    label='Car Spaces'
                    name='listing_search_advanced_filters_cars'
                    optional={false}
                    Input={Dropdown}
                    inputProps={{
                      items: SHOW_HIDE_OPTIONS
                    }}
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  {/* <Field
                    name='listing_search_advanced_filters_type'
                    label='Listing Type'
                    optional={false}
                    Input={Checkbox}
                  /> */}
                  <Field
                    label='Listing Type'
                    name='listing_search_advanced_filters_type'
                    optional={false}
                    Input={Dropdown}
                    inputProps={{
                      items: SHOW_HIDE_OPTIONS
                    }}
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  {/* <Field
                    name='listing_search_advanced_filters_category'
                    label='Category'
                    optional={false}
                    Input={Checkbox}
                  /> */}
                  <Field
                    label='Category'
                    name='listing_search_advanced_filters_category'
                    optional={false}
                    Input={Dropdown}
                    inputProps={{
                      items: SHOW_HIDE_OPTIONS
                    }}
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  {/* <Field
                    name='listing_search_advanced_filters_property_id'
                    label='Property ID'
                    optional={false}
                    Input={Checkbox}
                  /> */}
                  <Field
                    label='Property ID'
                    name='listing_search_advanced_filters_property_id'
                    optional={false}
                    Input={Dropdown}
                    inputProps={{
                      items: SHOW_HIDE_OPTIONS
                    }}
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  {/* <Field
                    name='listing_search_advanced_filters_build_area'
                    label='Build Area'
                    optional={false}
                    Input={Checkbox}
                  /> */}
                  <Field
                    label='Build Area'
                    name='listing_search_advanced_filters_build_area'
                    optional={false}
                    Input={Dropdown}
                    inputProps={{
                      items: SHOW_HIDE_OPTIONS
                    }}
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  {/* <Field
                    name='listing_search_advanced_filters_land_area'
                    label='Land Area'
                    optional={false}
                    Input={Checkbox}
                  /> */}
                  <Field
                    label='Land Area'
                    name='listing_search_advanced_filters_land_area'
                    optional={false}
                    Input={Dropdown}
                    inputProps={{
                      items: SHOW_HIDE_OPTIONS
                    }}
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  {/* <Field
                    name='listing_search_advanced_filters_new_homes'
                    label='Only Show New Homes'
                    optional={false}
                    Input={Checkbox}
                  /> */}
                  <Field
                    label='Only Show New Homes'
                    name='listing_search_advanced_filters_new_homes'
                    optional={false}
                    Input={Dropdown}
                    inputProps={{
                      items: SHOW_HIDE_OPTIONS
                    }}
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  {/* <Field
                    name='listing_search_advanced_filters_auctions'
                    label='Only Show Auctions'
                    optional={false}
                    Input={Checkbox}
                  /> */}
                  <Field
                    label='Only Show Listings with Auctions'
                    name='listing_search_advanced_filters_auctions'
                    optional={false}
                    Input={Dropdown}
                    inputProps={{
                      items: SHOW_HIDE_OPTIONS
                    }}
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  {/* <Field
                    name='listing_search_advanced_filters_availability'
                    label='Availability'
                    optional={false}
                    Input={Checkbox}
                  /> */}
                  <Field
                    label='Availability'
                    name='listing_search_advanced_filters_availability'
                    optional={false}
                    Input={Dropdown}
                    inputProps={{
                      items: SHOW_HIDE_OPTIONS
                    }}
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  {/* <Field
                    name='listing_search_advanced_filters_state'
                    label='State (AU) or Region (NZ)'
                    optional={false}
                    Input={Checkbox}
                  /> */}
                  <Field
                    label='State (AU) or Region (NZ)'
                    name='listing_search_advanced_filters_state'
                    optional={false}
                    Input={Dropdown}
                    inputProps={{
                      items: SHOW_HIDE_OPTIONS
                    }}
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  {/* <Field
                    name='listing_search_advanced_filters_features'
                    label='Features'
                    optional={false}
                    Input={Checkbox}
                  /> */}
                  <Field
                    label='Features'
                    name='listing_search_advanced_filters_features'
                    optional={false}
                    Input={Dropdown}
                    inputProps={{
                      items: SHOW_HIDE_OPTIONS
                    }}
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  {/* <Field
                    name='listing_search_advanced_filters_tags'
                    label='Tags'
                    optional={false}
                    Input={Checkbox}
                  /> */}
                  <Field
                    label='Tags'
                    name='listing_search_advanced_filters_tags'
                    optional={false}
                    Input={Dropdown}
                    inputProps={{
                      items: SHOW_HIDE_OPTIONS
                    }}
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  <PrimaryButton onClick={submitForm} isLoading={isSubmitting}>
                    Save
                  </PrimaryButton>
                </Box>

                <Heading
                  style={{
                    marginTop: '40px',
                    marginBottom: '20px',
                    marginLeft: '10px'
                  }}
                >
                  Custom Price Increments
                </Heading>

                <Box padding='10px' flex={1}>
                  <Field
                    name='price_increments'
                    label='Price Increments'
                    Input={CodeInput}
                    inputProps={{
                      mode: 'text',
                      theme: 'github',
                      charLimit: 9000
                    }}
                    optional={false}
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  <Field
                    name='rental_price_increments'
                    label='Rental Price Increments'
                    Input={CodeInput}
                    inputProps={{
                      mode: 'text',
                      theme: 'github',
                      charLimit: 9000
                    }}
                    optional={false}
                  />
                </Box>

                <Box padding='10px' flex={1}>
                  <PrimaryButton onClick={submitForm} isLoading={isSubmitting}>
                    Save
                  </PrimaryButton>
                </Box>
              </Form>
            )}
          </ReactForms>
        </RenderLoading>
      </Box>
    );
  }
}

export default ListingSearch;
