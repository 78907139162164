export default {
  /**
   * REDIRECTS
   */
  HOME: {
    config: {
      path: '/',
      exact: true,
      redirect: {
        path: '/websites'
      }
    }
  },

  LOGIN: {
    config: {
      path: '/login',
      Component: require('view/screens/auth/login').default,
      redirect: {
        path: '/websites'
      }
    }
  },

  FORGOT_PASSWORD: {
    config: {
      path: '/forgot-password',
      Component: require('view/screens/auth/forgot-password').default,
      redirect: {
        path: '/websites'
      }
    }
  },

  LOGOUT: {
    config: {
      path: '/logout',
      Component: require('view/screens/wordpress-iframe-logout').default
    }
  },

  /**
   * BASE LIST
   */

  SYNCS: {
    config: {
      path: '/account/syncs',
      Component: require('view/screens/account/syncs/list').default
    },
    CREATE: {
      config: {
        path: '/account/syncs/new',
        Component: require('view/dialogs/account/syncs/create').default
      }
    }
  },

  WEBSITES: {
    config: {
      path: '/websites',
      Component: require('view/screens/websites').default
    }
  },

  /**
   * CONTENT
   */
  CONTENT_POSTS: {
    config: {
      path: '/website/:websiteId/content/posts',
      Component: require('view/screens/wordpress-iframe').default,
      wordpressUrl: '/edit.php?type=posts'
    }
  },

  CONTENT_PAGES: {
    config: {
      path: '/website/:websiteId/content/pages',
      Component: require('view/screens/wordpress-iframe').default,
      wordpressUrl: '/edit.php?post_type=page'
    }
  },

  CONTENT_LISTINGS: {
    config: {
      path: '/website/:websiteId/content/listings',
      Component: require('view/screens/wordpress-iframe').default,
      wordpressUrl: '/edit.php?post_type=listings'
    }
  },

  CONTENT_AGENCY: {
    config: {
      path: '/website/:websiteId/content/agency-profiles',
      Component: require('view/screens/wordpress-iframe').default,
      wordpressUrl: '/edit.php?post_type=agency'
    }
  },

  CONTENT_STAFF: {
    config: {
      path: '/website/:websiteId/content/staff-members',
      Component: require('view/screens/wordpress-iframe').default,
      wordpressUrl: '/edit.php?post_type=staff'
    }
  },

  CONTENT_DEVELOPMENTS: {
    config: {
      path: '/website/:websiteId/content/developments',
      Component: require('view/screens/wordpress-iframe').default,
      wordpressUrl: '/edit.php?post_type=developments'
    }
  },

  CONTENT_PROJECTS: {
    config: {
      path: '/website/:websiteId/content/projects',
      Component: require('view/screens/wordpress-iframe').default,
      wordpressUrl: '/edit.php?post_type=projects'
    }
  },

  CONTENT_TESTIMONIALS: {
    config: {
      path: '/website/:websiteId/content/testimonials',
      Component: require('view/screens/wordpress-iframe').default,
      wordpressUrl: '/edit.php?post_type=testimonials'
    }
  },

  CONTENT_SUBURBS: {
    config: {
      path: '/website/:websiteId/content/suburbs',
      Component: require('view/screens/wordpress-iframe').default,
      wordpressUrl: '/edit.php?post_type=suburbs'
    }
  },

  // CONTENT_IMPORT: {
  //   config: {
  //     path: '/website/:websiteId/content/import',
  //     Component: require('view/screens/wordpress-iframe').default,
  //     wordpressUrl: '/admin.php?import=wordpress'
  //   }
  // },

  /**
   * DESIGN
   */
  // DESIGN_HOMEPAGE: {
  //   config: {
  //     path: '/website/:websiteId/design/homepage',
  //     Component: require('view/screens/design/homepage-settings-screen').default
  //   }
  // },

  DESIGN_BLOCKS: {
    config: {
      path: '/website/:websiteId/design/homepage',
      Component: require('view/screens/design/block-screen').default
    }
  },

  DESIGN_HEADER: {
    config: {
      path: '/website/:websiteId/design/header-behaviour',
      Component: require('view/screens/design/header').default
    }
  },

  DESIGN_FOOTER: {
    config: {
      path: '/website/:websiteId/design/footer',
      Component: require('view/screens/design/footer').default
    }
  },

  DESIGN_SITE: {
    config: {
      path: '/website/:websiteId/design/site-info',
      Component: require('view/screens/design/siteinfo').default
    }
  },

  // DESIGN_SEARCH: {
  //   config: {
  //     path: '/website/:websiteId/design/search',
  //     Component: require('view/screens/design/search-filters').default
  //   }
  // },

  DESIGN_STYLES: {
    config: {
      path: '/website/:websiteId/design/styles',
      Component: require('view/screens/design/site-styles').default
    }
  },

  DESIGN_CUSTOM: {
    config: {
      path: '/website/:websiteId/design/custom',
      Component: require('view/screens/design/custom-css').default
    }
  },

  DESIGN_WIDGETS: {
    config: {
      path: '/website/:websiteId/design/widgets',
      Component: require('view/screens/wordpress-iframe').default,
      wordpressUrl: '/widgets.php'
    }
  },

  DESIGN_MEDIA: {
    config: {
      path: '/website/:websiteId/design/media',
      Component: require('view/screens/wordpress-iframe').default,
      wordpressUrl: '/upload.php'
    }
  },

  // DESIGN_THEME: {
  //   config: {
  //     path: '/website/:websiteId/design/theme',
  //     Component: require('view/screens/design/theme').default
  //   }
  // },

  /**
   * MARKETING
   */

  MARKETING_POPUPS: {
    config: {
      path: '/website/:websiteId/marketing/popups',
      Component: require('view/screens/marketing/popups/popup').default
    },

    MARKETING_POPUP_EDIT: {
      config: {
        path: '/website/:websiteId/marketing/popups/edit/:popupId',
        Component: require('view/dialogs/marketing/popups/edit').default
      }
    },

    MARKETING_POPUP_NEW: {
      config: {
        path: '/website/:websiteId/marketing/popups/new',
        Component: require('view/dialogs/marketing/popups/create').default
      }
    }
  },

  MARKETING_TRACKING: {
    config: {
      path: '/website/:websiteId/marketing/tracking',
      Component: require('view/screens/marketing/tracking').default
    }
  },

  MARKETING_SOCIAL: {
    config: {
      path: '/website/:websiteId/marketing/social',
      Component: require('view/screens/marketing/socials').default
    }
  },

  MARKETING_ROBOTS: {
    config: {
      path: '/website/:websiteId/marketing/robotss',
      Component: require('view/screens/marketing/robots').default
    }
  },

  /**
   * MENU
   */
  MENU: {
    config: {
      path: '/website/:websiteId/menu',
      Component: require('view/screens/wordpress-iframe').default,
      wordpressUrl: '/nav-menus.php'
    }
  },

  MENU_LOCATIONS: {
    config: {
      path: '/website/:websiteId/menu-settings',
      Component: require('view/screens/menus/settings').default
    }
  },

  FORMS_SETTINGS: {
    config: {
      path: '/website/:websiteId/forms-settings',
      Component: require('view/screens/forms/settings').default
    }
  },

  /**
   * FORMS
   */
  FORMS_LIST: {
    config: {
      path: '/website/:websiteId/forms',
      exact: true,
      Component: require('view/screens/forms/list').default
    }
  },

  FORM_CREATE_NEW: {
    config: {
      path: '/website/:websiteId/forms/new',
      Component: require('view/screens/forms/create').default
    }
  },

  FORM_EDIT: {
    config: {
      path: '/website/:websiteId/forms/edit/:formId',
      Component: require('view/screens/forms/create').default
    },

    NEW_ACTION: {
      config: {
        path: '/website/:websiteId/forms/edit/:formId/action/new',
        Component: require('view/dialogs/forms/action-create').default
      }
    },

    EDIT_ACTION: {
      config: {
        path: '/website/:websiteId/forms/edit/:formId/action/edit/:actionId',
        Component: require('view/dialogs/forms/action-edit').default
      }
    }
  },

  FORMS_DETAILS: {
    config: {
      path: '/website/:websiteId/forms/details/:formId',
      Component: require('view/screens/forms/details').default
    }
  },

  FORMS_SUBMISSIONS: {
    config: {
      path: '/website/:websiteId/submissions',
      Component: require('view/screens/forms/submissions').default
    },

    CREATE: {
      config: {
        path: '/website/:websiteId/submissions/new',
        Component: require('view/dialogs/forms/create-submission').default
      }
    },

    EDIT: {
      config: {
        path: '/website/:websiteId/submissions/:submissionId',
        Component: require('view/dialogs/forms/edit-submission').default
      }
    }
  },

  /**
   * ADMIN
   */
  ADMIN_DOMAINS: {
    config: {
      path: '/website/:websiteId/admin/domains',
      Component: require('view/screens/admin/domains').default
    },

    CREATE: {
      config: {
        path: '/website/:websiteId/admin/domains/new',
        Component: require('view/dialogs/admin/domains/create').default
      }
    },
    EDIT: {
      config: {
        path: '/website/:websiteId/admin/domains/edit/:domainId',
        Component: require('view/dialogs/admin/domains/edit').default
      }
    }
  },

  ADMIN_USERS: {
    config: {
      path: '/website/:websiteId/admin/users',
      Component: require('view/screens/admin/users').default
    },

    CREATE: {
      config: {
        path: '/website/:websiteId/admin/users/new',
        Component: require('view/dialogs/admin/users/create').default
      }
    },

    EDIT: {
      config: {
        path: '/website/:websiteId/admin/users/edit/:userId',
        Component: require('view/dialogs/admin/users/edit').default
      }
    }
  },

  ADMIN_REDIRECTS: {
    config: {
      path: '/website/:websiteId/admin/redirects',
      Component: require('view/screens/admin/redirects').default
    },

    CREATE: {
      config: {
        path: '/website/:websiteId/admin/redirects/new',
        Component: require('view/dialogs/admin/redirects/create').default
      }
    },

    EDIT: {
      config: {
        path: '/website/:websiteId/admin/redirects/edit/:redirectId',
        Component: require('view/dialogs/admin/redirects/edit').default
      }
    }
  },

  ADMIN_DEPARTMENTS: {
    config: {
      path: '/website/:websiteId/admin/departments',
      Component: require('view/screens/admin/agency-departments').default
    }
  },

  ADMIN_PROJECTS: {
    config: {
      path: '/website/:websiteId/admin/project-settings',
      Component: require('view/screens/admin/project-settings').default
    }
  },

  ADMIN_LISTING_SETTINGS: {
    config: {
      path: '/website/:websiteId/admin/listing-settings',
      Component: require('view/screens/admin/listing-settings').default
    }
  },

  ADMIN_LISTING_SEARCH: {
    config: {
      path: '/website/:websiteId/admin/listing-search',
      Component: require('view/screens/admin/listing-search').default
    }
  },

  LISTING_SOURCES: {
    config: {
      path: '/website/:websiteId/admin/listing-sources',
      Component: require('view/screens/admin/listing-sources').default
    },

    ADD: {
      config: {
        path: '/website/:websiteId/admin/listing-sources/add',
        Component: require('view/dialogs/admin/listing-sources/add').default
      }
    }
  },

  CUSTOM_JS: {
    config: {
      path: '/website/:websiteId/admin/custom-js',
      Component: require('view/screens/admin/custom-javascript').default
    }
  },

  /**
   * LANDING PAGES
   */
  HOMEPAGE_SEO: {
    config: {
      path: '/website/:websiteId/homepage-seo',
      Component: require('view/screens/design/homepage-seo').default
    }
  },
  POSTS_LANDING_PAGE: {
    config: {
      path: '/website/:websiteId/posts-landing-page',
      Component: require('view/screens/design/index-pages/posts-landing-page')
        .default
    }
  },
  LISTINGS_LANDING_PAGE: {
    config: {
      path: '/website/:websiteId/listings-landing-page',
      Component: require('view/screens/design/index-pages/listings-landing-page')
        .default
    }
  },
  OPENHOMES_LANDING_PAGE: {
    config: {
      path: '/website/:websiteId/openhomes-landing-page',
      Component: require('view/screens/design/index-pages/openhomes-landing-page')
        .default
    }
  },
  AGENCY_LANDING_PAGE: {
    config: {
      path: '/website/:websiteId/agency-landing-page',
      Component: require('view/screens/design/index-pages/agency-landing-page')
        .default
    }
  },
  STAFF_LANDING_PAGE: {
    config: {
      path: '/website/:websiteId/staff-landing-page',
      Component: require('view/screens/design/index-pages/staff-landing-page')
        .default
    }
  },
  TESTIMONIALS_LANDING_PAGE: {
    config: {
      path: '/website/:websiteId/testimonials-landing-page',
      Component: require('view/screens/design/index-pages/testimonials-landing-page')
        .default
    }
  },
  PROJECTS_LANDING_PAGE: {
    config: {
      path: '/website/:websiteId/projects-landing-page',
      Component: require('view/screens/design/index-pages/projects-landing-page')
        .default
    }
  }
};
