import { query } from '@rexlabs/model-generator';
import submissionsModel from 'data/models/entities/submissions';

export const submissionsQuery = query`{
  ${submissionsModel} (websiteId: ${(p) => p?.match?.params?.websiteId}) {
    id
    fields
    form_id
    form_name
    created_at
  }
}`;

export const generateSubmissionsQuery = query`{
  ${submissionsModel} (websiteId: ${(p) =>
  p?.match?.params?.websiteId}, formId: ${(p) => p?.match?.params?.formId}) {
        id
        fields
        form_id
        form_name
        created_at
  }
}`;
