import ROUTES from 'src/routes/app';

import IconLogo from 'assets/icon-logo.svg';
import ContentSvg from 'assets/icons/content.svg';
import AppearanceSvg from 'assets/icons/appearance.svg';
import MarketingSvg from 'assets/icons/marketing.svg';
import SettingSvg from 'assets/icons/settings.svg';

export default ({ match }) => [
  { id: 'icon-logo', isTitle: true, Icon: IconLogo },
  {
    id: 'content',
    label: 'Content',
    Icon: ContentSvg,
    subItems: [
      { isHeading: true, label: 'General Content' },
      {
        label: 'Homepage SEO',
        to: ROUTES.HOMEPAGE_SEO,
        params: match?.params
      },
      {
        label: 'Pages',
        to: ROUTES.CONTENT_PAGES,
        params: match?.params
      },
      {
        label: 'Media Library',
        to: ROUTES.DESIGN_MEDIA,
        params: match?.params
      },
      { isHeading: true, label: 'Blog' },
      {
        label: 'Index Page',
        to: ROUTES.POSTS_LANDING_PAGE,
        params: match?.params
      },
      {
        label: 'Blog Posts',
        to: ROUTES.CONTENT_POSTS,
        params: match?.params
      },
      { isHeading: true, label: 'Listings' },
      {
        label: 'Index Page',
        to: ROUTES.LISTINGS_LANDING_PAGE,
        params: match?.params
      },
      {
        label: 'Listings',
        to: ROUTES.CONTENT_LISTINGS,
        params: match?.params
      },
      {
        label: 'Open Homes',
        to: ROUTES.OPENHOMES_LANDING_PAGE,
        params: match?.params
      },
      // {
      //   label: 'Developments',
      //   to: ROUTES.CONTENT_DEVELOPMENTS,
      //   params: match?.params
      // },
      { isHeading: true, label: 'Agency' },
      {
        label: 'Agency Profile Settings',
        to: ROUTES.AGENCY_LANDING_PAGE,
        params: match?.params
      },
      {
        label: 'Agency Profiles',
        to: ROUTES.CONTENT_AGENCY,
        params: match?.params
      },
      { isHeading: true, label: 'Staff' },
      {
        label: 'Index Page',
        to: ROUTES.STAFF_LANDING_PAGE,
        params: match?.params
      },
      {
        label: 'Staff Members',
        to: ROUTES.CONTENT_STAFF,
        params: match?.params
      },
      { isHeading: true, label: 'Testimonials' },
      {
        label: 'Index Page',
        to: ROUTES.TESTIMONIALS_LANDING_PAGE,
        params: match?.params
      },
      {
        label: 'Testimonials',
        to: ROUTES.CONTENT_TESTIMONIALS,
        params: match?.params
      },
      // {
      //   label: 'Suburbs',
      //   to: ROUTES.CONTENT_SUBURBS,
      //   params: match?.params
      // },
      { isHeading: true, label: 'Projects' },
      {
        label: 'Index Page',
        to: ROUTES.PROJECTS_LANDING_PAGE,
        params: match?.params
      },
      {
        label: 'Individual Projects',
        to: ROUTES.CONTENT_PROJECTS,
        params: match?.params
      }
    ]
  },
  {
    id: 'appearance',
    label: 'Appearance',
    Icon: AppearanceSvg,
    subItems: [
      { isHeading: true, label: 'General' },
      {
        label: 'Company Branding',
        to: ROUTES.DESIGN_STYLES,
        params: match?.params
      },
      {
        label: 'Homepage Styles',
        to: ROUTES.DESIGN_BLOCKS,
        params: match?.params
      },
      {
        label: 'Header',
        to: ROUTES.DESIGN_HEADER,
        params: match?.params
      },
      {
        label: 'Footer',
        to: ROUTES.DESIGN_FOOTER,
        params: match?.params
      },
      {
        label: 'Menus',
        to: ROUTES.MENU,
        params: match?.params
      }
      // {
      //   label: 'Theme Switcher',
      //   to: ROUTES.DESIGN_THEME,
      //   params: match?.params
      // }
    ]
  },
  {
    id: 'marketing',
    label: 'Marketing',
    Icon: MarketingSvg,
    subItems: [
      { isHeading: true, label: 'General' },
      // {
      //   label: 'Popups',
      //   to: ROUTES.MARKETING_POPUPS,
      //   params: match?.params
      // },
      {
        label: 'Social links',
        to: ROUTES.MARKETING_SOCIAL,
        params: match?.params
      },
      {
        label: 'Tracking',
        to: ROUTES.MARKETING_TRACKING,
        params: match?.params
      },

      { isHeading: true, label: 'Forms' },
      {
        label: 'Forms',
        to: ROUTES.FORMS_LIST,
        params: match?.params
      },
      {
        label: 'Submissions',
        to: ROUTES.FORMS_SUBMISSIONS,
        params: match?.params
      },
      {
        label: 'Form Settings',
        to: ROUTES.FORMS_SETTINGS,
        params: match?.params
      }
    ]
  },
  {
    id: 'settings',
    label: 'Settings',
    isBottom: true,
    Icon: SettingSvg,
    subItems: [
      { isHeading: true, label: 'General' },
      {
        label: 'Site Information',
        to: ROUTES.DESIGN_SITE,
        params: match?.params
      },
      {
        label: 'Domains',
        to: ROUTES.ADMIN_DOMAINS,
        params: match?.params
      },
      {
        label: 'Redirects',
        to: ROUTES.ADMIN_REDIRECTS,
        params: match?.params
      },
      {
        label: 'Agency Departments',
        to: ROUTES.ADMIN_DEPARTMENTS,
        params: match?.params
      },
      {
        label: 'Project Settings',
        to: ROUTES.ADMIN_PROJECTS,
        params: match?.params
      },
      {
        label: 'Listing Settings',
        to: ROUTES.ADMIN_LISTING_SETTINGS,
        params: match?.params
      },
      {
        label: 'Listing Search Settings',
        to: ROUTES.ADMIN_LISTING_SEARCH,
        params: match?.params
      },
      {
        label: 'Users',
        to: ROUTES.ADMIN_USERS,
        params: match?.params
      },

      { isHeading: true, label: 'CRM Connection' },
      {
        label: 'Listing Sources',
        to: ROUTES.LISTING_SOURCES,
        params: match?.params
      },
      // {
      //   label: 'Listing Logs',
      //   to: ROUTES.LISTING_SYNC_LOGS,
      //   params: match?.params
      // },

      { isHeading: true, label: 'Technical' },
      {
        label: 'Custom CSS',
        to: ROUTES.DESIGN_CUSTOM,
        params: match?.params
      },
      {
        label: 'Custom JS',
        to: ROUTES.CUSTOM_JS,
        params: match?.params
      },
      {
        label: 'Robots.txt',
        to: ROUTES.MARKETING_ROBOTS,
        params: match?.params
      }
      // {
      //   label: 'Import Data',
      //   to: ROUTES.CONTENT_IMPORT,
      //   params: match?.params
      // }
    ]
  }
];
